﻿.stories-section {
  @include media('<tablet') {
    .slide {
      width: 304px;
    }
  }

  .card {

    @include media('<tablet') {
      .card-body {
        padding: 32px;
      } 
    }

    .card-img-top {
      transform: none;
    }

    .btn-play {
      z-index: 20;
      cursor: pointer;
    }
  }
}
