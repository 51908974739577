// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------


.footer {
	background-color: $primary;
	color: $white;
	padding: 95px 0 98px;
	position: relative;

	@include media ('>=desktop') {
		padding: 96px 0 75px;
		background-image: url("../images/aoc-footer.png");
		background-repeat: repeat-x;
		background-position: left bottom;
		background-size: auto 54px;
	}

	.logo {
		@include media ('>=desktop') {
			margin-top: 0;
			width: 287px;
			margin-bottom: 31px;
		}
	}

	a {
		&:hover {
			color: $success;
		}
	}
}

.footer-content {
	padding-bottom: 56px;
	background-image: url("../images/aoc-footer.png");
	background-repeat: repeat-x;
	background-position: left bottom 20px;
	background-size: auto 54px;

	@include media ('>=desktop') {
		margin-bottom: 11px;
		background: none;
	}

	.description-box {
		@include media ('>=desktop') {
			width: calc(100% - 287px);
			padding-right: 20px;
		}

		.description {
			font-size: 14px;
			line-height: 1.4286;
			max-width: 740px;

			a {
				text-decoration: underline;
				color: inherit;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.social-box {
		@include media ('>=desktop') {
			max-width: 287px;
			margin-left: auto;
		}
	}

	.social-list {
		@include media ('>=desktop') {
			margin-bottom: 0;
		}
	}
}

.social-list {
	@extend %listreset;

	margin: 0 -8px;

	@include media ('>=desktop') {
		margin: 0 -10px;
	}

	li {
		padding: 0 11px 15px;

		@include media ('>=desktop') {
			padding: 0 10px 15px;
		}
	}

	a {
		color: inherit;
		font-size: 34px;

		@include media ('>=desktop') {
			font-size: 26px;
		}
	}
}

.newslatter-holder {
	h6 {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 18px;
		font-family: $font-family-base;

		@include media ('>=desktop') {
			margin: -2px 0 18px 5px;
		}
	}

	.recaptcha-text {
		color: $gray-500;
		margin-top: 6px;
		font-size: 12px;

		a {
			color: inherit;

			&:hover {
				color: $success;
			}
		}
	}
}

.newslatter-form {
	margin-bottom: 47px;

	@include media ('>=desktop') {
		margin-bottom: 56px;
	}

	.form-control {
		height: 36px;
		border-radius: 3em;
		box-shadow: inset 0 1px 2px rgba(133, 133, 133, 0.25);
		background-color: $white;
		border: none;
		font-size: 16px;
		padding: 5px 19px;
		color: $primary;
		border-color: $white;
		margin-bottom: 16px;
		font-weight: 600;

		@include placeholder {
			color: $gray-600;
		}

		@include media ('>=tablet') {
			margin-right: 16px;
			margin-bottom: 0;
		}

		@include media ('>=desktop') {
			max-width: 278px;
		}
	}

	.btn {
		min-width: 126px;

		&.btn-sm {
			padding: 8px 23px 6px;
		}
	}
}

.copyright-holder {
	border-top: 1px solid $gray-600;
	padding: 32px 0 0;

	@include media ('>=desktop') {
		padding: 22px 0;
	}

	.footer-menu {
		@include media ('>=desktop') {
			margin-left: auto;
		}
	}
}

.copyright-list,
.footer-menu {
	font-size: 14px;

	a {
		color: $white;
		text-decoration: none;
	}
}

.logo-list {
	margin: 0;

	li {
		margin-right: 32px;

		@include media ('>=desktop') {
			margin-right: 0;
		}
	}

	@include media ('>=desktop') {
		margin-right: 16px;
	}

	.mob-logo {
		width: 111px;
	}

	img {
		@include media ('>=desktop') {
			max-width: 102px;
		}
	}
}

.copyright-list {
	margin: 0 -2px 12px;

	@include media ('>=desktop') {
		margin: 0 -2px;;
	}

	li {
		margin-bottom: 22px;

		@include media ('>=desktop') {
			padding: 0 2px;
			margin-bottom: 0;
		}
	}
}

.footer-menu {
	margin: 0;

	@include media ('>=desktop') {
		margin: 0 -14px;
	}

	li {
		margin-bottom: 24px;

		@include media ('>=desktop') {
			margin-bottom: 0;
			padding: 0 29px;
		}
	}
}

.artist-sign {
	font-size: 10px;
	line-height: 11px;

	@include media ('<tablet') {
		position: absolute;
		left: 0;
		bottom: 10px;
		width: 100%;
	}
}