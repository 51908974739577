﻿.article {
    padding: 16px 0 64px;

    @include media ('>=desktop') {
        padding: 56px 0 96px;
    }

    .filter-wrapp {
        margin-bottom: 24px;

        @include media ('>=desktop') {
            margin-bottom: 48px;
        }
    }

    .article-info {
        padding-bottom: 64px;
    }

    .article-content {
        display: flex;
        flex-direction: column;
        gap: 64px;

        @include media ('>=tablet') {
            gap: 96px;
        }

        .content-holder {
            padding-bottom: 0;
        }

        .article-rich-text {
            margin-bottom: 0;
            margin-top: 0;
        }

        img {
            position: static;
            margin: 0;
        }

        .article-main-image {
            margin-bottom: 0;
        }

        .btn-holder {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }

        .article-video {
            margin: 0;
        }

        .article-hero-image {

            img {
                height: 343px;
                max-width: 100%;
                object-fit: cover;

                @include media ('>=tablet') {
                    height: 513px;
                }

                @include media ('>=desktop') {
                    height: 535px;
                }
            }
        }
    }
}


.filter-wrapp::after {
    display: none;
}
