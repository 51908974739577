.filter-wrapp {
	margin: 0 -16px 23px;
	position: relative;
	padding: 0 0 14px;

	@include media ('>=tablet') {
		padding: 0;
		margin: 0 0 30px;
	}

	&::before {
		@include transition(opacity visibility);
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 80px;
		background-image: linear-gradient(to left, $white 48.96%, rgba($white, 0) 100%);
		z-index: 2;
		pointer-events: none;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 16px;
		right: 16px;
		height: 2px;
		border-radius: 2px;
		background-color: $gray-300;
		z-index: 3;
	}

	&.end-position {
		&::before {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.scrollable {
	overflow: auto hidden;
	scrollbar-width: none;
	touch-action: pan-x pan-y pinch-zoom;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		display: none;
	}
}

$hover-item-color: #414141;

.location-list,
.filter-list {
	margin: 0;
	white-space: nowrap;
	display: flex;
	text-align: center;
	font-size: $font-size-sm;
	line-height: 1.25;

	li {
		padding: 0 15px;

		@include media ('>=desktop') {
			padding: 0 20px 0 21px;
		}

		&:first-child {
			margin-left: auto;
			@include media ('<tablet') {
				padding-left: 16px;
			}
		}

		&:last-child {
			margin-right: auto;
			@include media ('<tablet') {
				padding-right: 16px;
			}
		}

		&.active {
			a {
				color: $primary;

				&::before {
					background-color: $primary;
				}
			}
		}
	}

	a {
		color: $gray-700;
		display: flex;
		flex-direction: column;
		align-items: center;
		white-space: nowrap;
		position: relative;
		padding-bottom: 9px;
		text-align: center;
		height: 100%;

		&:hover {
			@include media ('>=tablet') {
				color: $hover-item-color;
			}

			&::before {
				@include media ('>=tablet') {
					background-color: $hover-item-color;
				}
			}
		}

		&::before {
			@include transition(background-color);
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 2px;
			border-radius: 2px;
			background-color: transparent;
		}

		span {
			@include size(24px);
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 23px;
			line-height: 1;
			margin-bottom: 4px;

			&.icon-layers {
				font-size: 20px;
			}

			&.icon-shopping-bag {
				font-size: 21px;
			}

			&.icon-family {
				font-size: 24px;
			}

			&.icon-home {
				font-size: 22px;
			}

			&.icon-city {
				font-size: 26px;

				@include media ('>=phone') {
					font-size: 30px;
				}
			}

			&.icon-map {
				font-size: 20px;

				@include media ('>=phone') {
					font-size: 26px;
				}
			}

			&.icon-education {
				font-size: 20px;

				@include media ('>=phone') {
					font-size: 22px;
				}
			}

			&.icon-ship {
				font-size: 25px;

				@include media ('>=phone') {
					font-size: 30px;
				}
			}
		}

		img {
			height: 24px;
			max-width: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			margin-bottom: 3px;

			@include media ('>=phone') {
				height: 28px;
				max-width: 34px;
				margin-bottom: 6px;
			}
		}
	}
}