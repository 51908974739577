// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #F9FAFB;
$gray-200: #F2F4F7;
$gray-300: #DDE0E6;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6F6F6F;
$gray-700: #414141;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000;

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #512F78;
$pink:    #FFA6CC;
$red:     #dc3545 !default;
$orange:  #FF921F;
$yellow:  #F7E65C;
$green:   #198754 !default;
$teal:    #A8FABA;
$cyan:    #80E5F7;
$dark-blue: #22344C;
$darken-blue: #111A26;
$darken-green: #023C0F;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $darken-blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $darken-blue;
$secondary:     $gray-600 !default;
$success:       $teal;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100 !default;
$dark:          $dark-blue;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "pink":       $pink,
  "purple":     $purple,
  "orange":     $orange
);

$grid-gutter-width: 30px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
    wide: 1400px,
);

$container-max-widths: (
    sm: 1172px + ($grid-gutter-width * 2),
);

// Options

$enable-rfs:                  false;

// border radius
$rounded-circle: 50%;

// Components

$box-shadow-sm:   0 2px 4px rgba(9, 8, 66, .08), 0 4px 24px rgba(9, 8, 66, .04);

// Typography

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Gilroy', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$font-family-sans-serif-secondary:      'roc-grotesk', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base:            $font-family-sans-serif;
$base-font-path: '../fonts';
// stylelint-enable value-keyword-case


$font-weight-thin: 100;
$font-weight-ulight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

$font-size-xl:                1.5625rem; // 25px
$font-size-lg:                1.125rem;  // 18px
$font-size-base:              1rem;      // 16px
$font-size-md:                .875rem;   // 14px
$font-size-sm:                .75rem;    // 12px

$font-sizes: (
    'xl': $font-size-xl,
    'lg': $font-size-lg,
    'base': $font-size-base,
    'md': $font-size-md,
    'sm': $font-size-sm,
);

$h1-font-size:                2.5rem;     // 40px
$h2-font-size:                2.1rem;     // 33.6px
$h3-font-size:                1.7rem;     // 27.2px
$h4-font-size:                1.25rem;    // 20px
$h5-font-size:                1.125rem;   // 18px
$h6-font-size:                $font-size-base;

$headings-font-family:        $font-family-sans-serif-secondary;
$headings-font-weight:        $font-weight-semibold;
$headings-line-height:        1.1;


$font-size-base: $font-size-lg;
$line-height-base:   1.4444;

$fluid-min: 360px;
$fluid-max: map-get($grid-breakpoints, 'wide');

// Base transition function
$transition-duration-base: 0.15s;
$transition-easing-base: cubic-bezier(0.3, 0.86, 0.36, 0.95);

// body

$body-bg: $white;
$body-color: $primary;

// Links

$link-color:   $primary;
$link-decoration:   none;


// Buttons

$btn-font-family:             $font-family-sans-serif-secondary;
$btn-font-size:               $font-size-base;
$btn-font-size-sm:            1rem;
$btn-font-weight:             $font-weight-medium;

$btn-border-radius:           50rem;
$btn-border-radius-sm:        50rem;
$btn-border-radius-lg:        50rem;

// Cards

$card-border-radius:                0;
$card-inner-border-radius:          0;
$card-box-shadow: $box-shadow-sm;
$card-border-width:                 0;
