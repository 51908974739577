﻿.image-showcase-container {
    .image-showcase-single {
        height: 318px;

        @include media ('>=tablet') {
            height: 535px;
        }
    }

    img.image-showcase-item-1 {
        height: 300px;

        @include media ('>=tablet') {
            height: 531px;
        }
    }

    img.image-showcase-item-2 {
        height: 171px;

        @include media ('>=tablet') {
            height: 313px;
        }
    }

    img.image-showcase-item-3 {
        height: 223px;

        @include media ('>=tablet') {
            height: 343px;
        }
    }

    img.image-showcase-item-4 {
        height: 300px;

        @include media ('>=tablet') {
            height: 344px;
        }
    }

    img.image-showcase-item-5 {
        height: 171px;

        @include media ('>=tablet') {
            height: 232px;
        }
    }

    .showcase-caption {
        width: 100%;
        color: $gray-600;
        font-size: 14px;
        text-align: center;
        margin: 0 auto 0 auto !important;
    }

    &.single {
        .showcase-caption {
            margin: 16px auto 0 auto !important;

            @include media ('>=tablet') {
                margin: 24px auto 0 auto !important;
                width: 70%;
            }
        }
    }

    &.multiple {
        .showcase-caption {
            position: relative;
            top: -16px;

            @include media ('>=tablet') {
                top: 8px;
                width: 70%;
            }
        }
    }
}
