@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icomoon-hash}') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'],
%icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-city {
  &:before {
    content: $icon-city; 
  }
}
.icon-education {
  &:before {
    content: $icon-education; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-medical {
  &:before {
    content: $icon-medical; 
  }
}
.icon-plane {
  &:before {
    content: $icon-plane; 
  }
}
.icon-ship {
  &:before {
    content: $icon-ship; 
  }
}
.icon-arrow-left-bold {
  &:before {
    content: $icon-arrow-left-bold; 
  }
}
.icon-arrow-right-bold {
  &:before {
    content: $icon-arrow-right-bold; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-family {
  &:before {
    content: $icon-family; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-layers {
  &:before {
    content: $icon-layers; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-paper-plane-1 {
  &:before {
    content: $icon-paper-plane-1; 
  }
}
.icon-pointer {
  &:before {
    content: $icon-pointer; 
  }
}
.icon-ramen {
  &:before {
    content: $icon-ramen; 
  }
}
.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag; 
  }
}
.icon-startup {
  &:before {
    content: $icon-startup; 
  }
}
.icon-trending {
  &:before {
    content: $icon-trending; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-arts {
  &:before {
    content: $icon-arts; 
  }
}
.icon-jobs {
  &:before {
    content: $icon-jobs; 
  }
}
.icon-nature {
  &:before {
    content: $icon-nature; 
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi; 
  }
}
.icon-train {
  &:before {
    content: $icon-train; 
  }
}
.icon-coffee {
  &:before {
    content: $icon-coffee; 
  }
}
.icon-beach {
  &:before {
    content: $icon-beach; 
  }
}
