
// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
	letter-spacing: .03em;
	padding: rem-calc(14 23 12);

	&:not([class="btn-outline-"]) {
		color: $primary;
	}

	&.btn-sm {
		padding: rem-calc(7 23);
		line-height: 1.25;
	}

	&.btn-primary {
		color: $white;

		&:hover {
			background-color: $dark-blue;
			border-color: $dark-blue;
		}
	}

	&.btn-success {
		&:hover {
			background-color: #92E0A3;
			border-color: #92E0A3;
		}
	}

	&.btn-info {
		&:hover {
			background-color: #57CBE3;
			border-color: #57CBE3;
		}
	}

	&.btn-warning {
		&:hover {
			background-color: #DFD053;
			border-color: #DFD053;
		}
	}

	&.btn-pink {
		&:hover {
			background-color: #DE85AB;
			border-color: #DE85AB;
		}
	}

	&.btn-orange {
		&:hover {
			background-color: #E3831C;
			border-color: #E3831C;
		}
	}

	&.btn-light {
		background-color: $white;
		border-color: $white;

		&:hover {
			background-color: $gray-100;
			border-color: $gray-100;
		}
	}
}