﻿$feature-border-color: #F2F4F7;
$navy: #22344C;

.cost-comparison-section {
    h1 {
        font-size: 33.6px;
        line-height: 40px;

        @include media ('>=tablet') {
            font-size: 69px;
            line-height: 75px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .selector-container {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 52px;

        .divider {
            font-weight: 700;
            font-size: 23.2px;
            line-height: 28px;
            letter-spacing: 0.01em;
            color: #000000;
        }

        @include media ('>=tablet') {
            gap: 32px;
        }

        .btn-frozen {
            pointer-events: none;
        }
    }

    .cities-dropdown-container {
        position: relative;

        i {
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            margin-left: 10px;
            transform: rotate(0deg);
            transition: transform 0.3s linear;
        }

        .select-button {
            width: 160px;

            @include media ('>=tablet') {
                width: 190px;
            }

            @include media ('<=tablet') {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .select-button.opened {
            i {
                transform: rotate(180deg);
            }
        }
    }

    .cities-dropdown {
        position: absolute;
        z-index: 10;
        list-style: none;
        margin: 4px 0 0 0;
        width: 100%;
        border: 1px solid #E6FAFD;
        border-radius: 6px;
        padding: 0;
        display: none;
        background: #E6FAFD;

        li {
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #111A26;
            padding: 24px;
            cursor: pointer;
            text-align: center;

            &:hover {
                background-color: white;
                font-weight: 700;
            }

            &.hidden {
                display: none;
            }
        }
    }

    .features-tab {
        display: none;
        row-gap: 24px;

        @include media ('>=tablet') {
            row-gap: 55px;
        }

        &.active {
            display: flex;
        }

        .feature-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @include media ('>=tablet') {
                justify-content: flex-end;
            }
        }

        .feature {
            display: grid;
            grid-template-columns: 87px 1fr;
            grid-template-rows: 1fr;
            height: 144px;
            width: 322px;
            background: #FFFFFF;
            border: 1px solid $feature-border-color;
            box-shadow: 0px 2px 4px rgba(9, 8, 66, 0.08), 0px 4px 24px rgba(9, 8, 66, 0.04);
            border-radius: 45px;
            padding: 24px;

            .icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .icon {
                max-width: 60px;
                max-height: 60px;
            }

            .text-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                box-sizing: border-box;
                padding-left: 24px;
                border-left: 1px solid $feature-border-color;
            }

            .heading {
                font-weight: 700;
                font-size: 40px;
                line-height: 44px;
                color: $navy;
            }

            .subtext {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: #111A26;
            }
        }
    }

    .city-features-subheading {
        margin-bottom: 32px;
    }

    .bottom-text-container {
        margin-top: 16px;

        @include media ('>=tablet') {
            margin-top: 56px;
        }

        div {
            width: 100%;
            text-align: right;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #6F6F6F;
        }

        .source-link {
            color: $navy;
            text-decoration: underline;
        }
    }
}
