﻿.newslatter-holder {
    margin-bottom: 30px;

    .newslatter-form {
        margin-bottom: 15px;
    }

    .input-validation-error {
        background-color: #FFDDD9 !important;
        border: 0.5px solid #E04034;
    }

    .subscription-message {
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        font-weight:700;
    }
    .success-message {
        color:#A8FABA;
    }
    .error-message {
        color:#E04034;
    }
}
