/* Slick slider styles */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.wc-slick-slider {
    margin-bottom: 30px;
    .j-content-center {
        justify-content: center;
    }
    .slick-next, .slick-prev {
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        background: 0 0;
        color: transparent;
        top: 50%;
        transform: translate(0,-50%);
        padding: 0;
        border: none;
        outline: 0;
        &:before {
            display: inline-block;
        }
    }

    .slick-slide {
        img {
            max-width: 100%;
        }
    }

    .slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
        outline: 0;
        background: 0 0;
        color: transparent
    }

    .slick-next:focus:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-prev:hover:before {
        opacity: 1
    }

    .slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
        opacity: .25
    }
    figure {
        margin: 1em 40px;
    }
    figcaption {
        margin-top: 4px;
        font-size: 14px;
    }
    .slick-dots li button {
        border: 2px solid #fff;
        background: 0 0;
        display: block;
        height: 20px;
        width: 20px;
        outline: 0;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        &:before {
            opacity: .25;
        }
    }
    .slick-dots li.slick-active button {
        background: none;
    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
    }
    .u-embed {
        position: relative;
        display: block;
        height: 0;
        padding: 0;
        overflow: hidden;
        padding-bottom: 100%
    }
    .u-embed--16by9 {
        padding-bottom: 56.25%
    }
    .u-embed__item,.u-embed iframe,.u-embed embed,.u-embed object,.u-embed video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        border: 0
    }
}