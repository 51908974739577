﻿#youtube-modal {
    &.fade {
        background-color: rgba(0,0,0, 0.95);
    }

    .modal-dialog {
        max-width: fit-content;

        .modal-content {
            background-color: transparent;
            top: 100px;
        }

        iframe {
            width: 80vw;
            height: 55vh;

            @include media ('>=tablet') {
                width: 65vw;
                height: 55vh;
            }
        }
    }

    .close {
        padding: 0;
        background-color: rgba(250, 250, 255, 0.9);
        width: 48px;
        height: 48px;
        border-radius: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -100px;
        position: absolute;
        top: -100px;
    }
}

$btn_play_size: 82px;

.btn-video-youtube {
    width: $btn_play_size;
    height: $btn_play_size;
    position: absolute;
    top: calc(50% - #{$btn_play_size/2});
    left: calc(50% - #{$btn_play_size/2});
    background-color: rgba(242, 244, 247, .8);
    color: $primary;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
    border-radius: $rounded-circle;
    cursor: pointer;

    &::before {
        @extend %icon;
        content: $icon-play;
        margin-left: 2px;
    }
}