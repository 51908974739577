﻿.ktc-section {
    .viewport-section {
        .move-effect {
            opacity: 1;
            transform: translateY(0);
        }

        &.move-effect {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
