// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  @Example usage of font-family mixin;
//
$font-primary: (
	'Regular': $font-weight-normal,
	'Semibold': $font-weight-semibold,
	'Bold': $font-weight-bold,
);
//
//  $font-secondary: (
//    'Thin': $font-weight-thin,
//    'Bold': $font-weight-bold,
//  );
//
//  @param {
//    $fontName: Filename: string;
//    $fontWeight: 100|300|400|normal|bold;
//    $fontStyle: normal/italic/etc;
//  }

@each $suffix, $weight in $font-primary {
	@include fontface('Gilroy', 'Gilroy-#{$suffix}', $weight, normal);
}
//
//  @each $suffix, $weight in $font-secondary {
//    @include fontface('Second Font', '${LocalFontName}-#{$suffix}', $weight, normal);
//    @include fontface('Second Font', '${LocalFontName}-#{$suffix}Italic', $weight, italic);
//  }
// -----------------------------------------------------------------------------
