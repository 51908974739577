.map-block {
	padding: 54px 0 74px;

	@include media ('>=desktop') {
		padding: 100px 0 240px;
	}

	@include media ('>=widescreen') {
		padding-top: 130px;
	}

	h2 {
		margin-bottom: 32px;

		@include media ('>=phone') {
			font-size: 48px;
			line-height: 1.1;
			margin-bottom: 47px;
		}

		@include media ('>=desktop') {
			font-size: 60px;
		}
	}

	.js-tab-hidden {
		display: block !important;
		left: 0 !important;
		position: absolute !important;
		top: 0 !important;
	}

	.location-list-wrapp {
		position: relative;

		&.end-position {
			&::before {
				opacity: 0;
				visibility: hidden;
			}
		}

		&::before {
			@include transition(opacity visibility);
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: -16px;
			width: 50px;
			background-image: linear-gradient(to left, $white 48.96%, rgba($white, 0) 100%);
			z-index: 20;
			pointer-events: none;

			@include media ('>=tablet') {
				display: none;
			}
		}
	}

	.scrollable {
		position: relative;
		z-index: 10;

		&.locations-wrapp {
			@include media ('<tablet') {
				margin: 0 -16px 30px;
				padding: 0;
				width: calc(100% + 32px);
				text-align: center;
			}

			@include media ('<phone') {
				padding: 0 16px;
			}

			@include media ('>=tablet') {
				margin-bottom: 30px;
			}

			@include media ('>=desktop') {
				margin-bottom: 48px;
			}
		}
	}

	.tab-item {
		@include transition(opacity visibility, .4s, ease-in);
		opacity: 0;
		visibility: hidden;

		@include media ('>=tablet') {
			width: 100%;
		}

		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
}

.location-list {
	@extend %listreset;

	margin: 0 -16px;
	flex-wrap: nowrap;

	@include media ('>=phone') {
		font-size: 14px;
		line-height: 1.4286;
		margin: 0;
		padding-top: 2px;
		display: inline-flex;
	}

	@include media ('>=tablet') {
		display: flex;
		padding-top: 0;
		margin: 0 -20px;
	}

	@include media ('>=desktop') {
		margin: 0;
	}

	li {
		@include media ('>=phone') {
			padding: 0 18px;
		}

		@include media ('>=desktop') {
			padding: 0 21px 0 22px;
		}

		&:last-child {
			@include media ('<tablet') {
				margin-right: auto;
			}
		}

		&:first-child {
			@include media ('<tablet') {
				margin-left: auto;
			}
		}
	}

	a {
		padding-bottom: 8px;
		white-space: normal;

		@include media ('>=tablet') {
			max-width: 80px;
		}

		@include media ('>=desktop') {
			padding-bottom: 14px;
			max-width: none;
		}

		br {
			@include media ('>=tablet') {
				display: none;
			}
		}

		span {
			margin-bottom: 3px;

			@include media ('>=phone') {
				font-size: 28px;
				margin-bottom: 10px;
			}
		}
	}
}

.map-content,
.tab-content {
	position: relative;
	overflow: hidden;
}

.map-content {
	img {
		display: block;
		width: 100%;
		max-width: none;
		height: auto;

		@include media ('>=1920px') {
			width: 1902px;
			margin: 0 auto;
		}
	}

	.tab-item {
		border-bottom: 11px solid;
		width: 100%;

		@include media ('>=phone') {
			border-bottom: 0;
		}

		&::before {
			@include media ('>=phone') {
				content: '';
				height: 11px;
				position: absolute;
				bottom: 0;
				left: 16px;
				right: 16px;
			}

			@include media ('>=desktop') {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
				width: 944px;
			}

			@include media ('>=1920px') {
				width: 1264px;
			}
		}

		&.theme-success {
			border-bottom-color: $success;

			&::before {
				@include media ('>=phone') {
					background-color: $success;
				}
			}
		}

		&.theme-orange {
			border-bottom-color: $orange;

			&::before {
				@include media ('>=phone') {
					background-color: $orange;
				}
			}
		}

		&.theme-warning {
			border-bottom-color: $warning;

			&::before {
				@include media ('>=phone') {
					background-color: $warning;
				}
			}
		}

		&.theme-pink {
			border-bottom-color: $pink;

			&::before {
				@include media ('>=phone') {
					background-color: $pink;
				}
			}
		}

		&.theme-info {
			border-bottom-color: $info;

			&::before {
				@include media ('>=phone') {
					background-color: $info;
				}
			}
		}

		&.theme-purple {
			border-bottom-color: $purple;

			&::before {
				@include media ('>=phone') {
					background-color: $purple;
				}
			}
		}
	}
}

.tab-content {
	.tab-item {
		font-size: 16px;
		line-height: 1.5;
		-webkit-touch-callout: none;

		.row {
			h2 {
				display: initial;
			}

			@include media ('>=phone') {
				margin: 0 -9px;
			}

			@include media ('>=tablet') {
				margin: 0 -12px;
			}

			@include media ('>=desktop') {
				margin: 0 -19px;
			}

			@include media ('>=1920px') {
				padding-right: 40px;
			}
		}

		[class*="col-"] {
			padding-bottom: 18px;

			@include media ('>=phone') {
				padding: 0 9px 18px;
			}

			@include media ('>=tablet') {
				padding: 0 12px 18px;
			}

			@include media ('>=desktop') {
				padding: 0 19px 18px;
			}

			@include media ('>=1920px') {
				padding-right: 11%;
			}

			&.btn-holder {
				padding-top: 18px;

				@include media ('>=phone') {
					padding-top: 13px;
				}

				@include media ('>=tablet') {
					padding-top: 8px;
				}

				@include media ('>=desktop') {
					padding-top: 6px;
				}
			}

			p {
				&:last-child {
					margin: 0;
				}
			}
		}
	}

	h2 {
		display: none;

		@include media ('>=phone') {
			display: block;
			font-size: 23.2px;
			line-height: 1.2069;
			margin-bottom: 14px;
		}
	}

	h4 {
		font-size: 16px;
		line-height: 1.5;
		font-family: $font-family-sans-serif;
		font-weight: $font-weight-bold;
		margin: 0;
		letter-spacing: 0;

		@include media ('>=phone') {
			font-size: 18px;
			line-height: 1.4444;
		}
	}
}

.map-content-container {
	@include media ('>=desktop') {
		padding: 0 40px;
		max-width: none;
	}

	@include media ('>=xl') {
		padding: 0 40px;
		max-width: none;
	}
}

.map-content-holder {
	padding: 32px 16px 16px;
	margin: 0 -16px;

	@include media ('>=phone') {
		margin: 0;
		position: relative;
		background-color: $white;
		padding: 35px 25px 24px;
	}

	@include media ('>=tablet') {
		margin: 0 -32px;
		padding: 37px 25px 16px;
	}

	@include media ('>=desktop') {
		margin: 0 auto;
		padding: 48px 48px 30px;
		width: 944px;
	}

	@include media ('>=1920px') {
		width: 1264px;
		padding: 48px 48px 30px;
	}
}