﻿$background_color: #430500;
$text_color: #FAFAFF;
$square_animation_duration: 4s;
$text_animation_duration: 2s;


@keyframes squareToRect {
    from {
        width: 24px;
    }

    to {
        width: 100%;
    }
}

@keyframes textAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


#acknowledgment-modal {
    .modal-dialog {
        width: 100%;
        height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .modal-header {
        @include media ('<tablet') {
            display: none;
        }
    }

    .modal-header, .modal-footer {
        border: none;
        font-size: 16px;
    }

    .modal-content {
        width: 100%;
        height: 100%;
        border-radius: 0;
        background-color: $background_color;
        border: none;
        padding: 32px;
        color: $text_color;
        background-image: url("../images/aoc-circle-mobile.png");
        background-repeat: no-repeat;
        background-position: right bottom;

        @include media ('>=tablet') {
            background-image: url("../images/aoc-circle.png");
            background-position: right center;
            background-size: auto 720px;
        }


        @include media ('>=desktop') {
            width: 1126px;
            height: 800px;
            background-image: url("../images/aoc-circle.png");
            background-position: right center;
            background-size: auto 720px;
        }

        .modal-body {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-start;

            @include media ('>=tablet') {
                width: 500px;
                justify-content: center;
                gap: 40px;
            }


            @include media ('>=desktop') {
                width: 616px;
                margin-left: 84px;
                justify-content: center;
                gap: 40px;
            }

            .aoc-square-container {
                width: 100%;
            }

            .aoc-square {
                width: 24px;
                height: 24px;
                display: block;
                background-color: $text_color;
                animation-name: squareToRect;
                animation-duration: $square_animation_duration;
                animation-fill-mode: forwards;
            }

            .aoc-heading {
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                animation-name: textAnimation;
                animation-duration: $text_animation_duration;
                animation-fill-mode: forwards;

                @include media ('>=desktop') {
                    font-size: 32px;
                    line-height: 32px;
                }
            }

            .aoc-text {
                font-size: 14px;
                line-height: 20px;
                opacity: 0;
                animation-name: textAnimation;
                animation-duration: $text_animation_duration;
                animation-fill-mode: forwards;
                animation-delay: $text_animation_duration;

                @include media ('>=desktop') {
                    font-size: 18px;
                    line-height: 19.8px;
                }
            }



            .btn-close-modal {
                @include media ('<desktop') {
                    width: 100%;
                }
            }
        }
    }

    .modal-footer {
        justify-content: flex-start;

        @include media ('<desktop') {
            font-size: 12px;
            line-height: 13.2px;
        }
    }
}
