.btn {
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-focus-shadow-rgb: 143, 213, 158;
}

// 1. Configuration and helpers
@import 'abstracts/variables';
@import 'abstracts/functions';

// 2. Vendors
@import 'vendors/bootstrap-utilities';
@import '~include-media/dist/include-media';
@import 'abstracts/rebuilded-mixins';
@import 'vendors/bootstrap';
@import 'vendors/icomoon/styles';
@import 'vendors/slick';
@import 'vendors/fancybox';
@import 'vendors/jcf';
@import 'abstracts/mixins';

// 3. Base styles and elements
@import 'base/base';
@import 'base/helpers';
@import 'base/fonts';
@import 'base/typography';

// 4. Layout-related sections
@import 'layout/header';
@import 'layout/common';
@import 'layout/footer';

// 5. Components
@import 'components/accordion';
@import 'components/button';
@import 'components/intro';
@import 'components/cards';
@import 'components/filter-panel';
@import 'components/map-block';
@import 'components/youtube-video';
@import 'components/cards-features';
@import 'components/image-gallery';
@import 'components/image-links-gallery';
@import 'components/media-card-panel';
@import 'components/image-showcase';
@import 'components/article-page';
@import 'components/hero';
@import 'components/page-builder-stub';
@import 'components/contact-panel';
@import 'components/footer';
@import 'components/cost-comparison-component';
@import 'components/acknowledgment-of-country';

.error-container {
    display: flex;
    flex-direction: row;
    padding: 80px 40px;
    justify-content: center;
    align-items: center;

    .not-found-container,
    .server-error-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

