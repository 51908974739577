﻿.hero {
    position: relative;
    margin-top: 16px;
    padding-top: 56px;

    @include media ('>=tablet') {
        padding-top: 0;
        margin-top: 30px;
    }

    @include media ('>=desktop') {
        max-width: 2000px;
        margin: 96px auto 0;
    }

    .container {
        position: relative;
        text-align: center;

        @include media ('>=tablet') {
            text-align: left;
        }
    }

    h1 {
        font-size: 40px;
        line-height: 1.1;
        margin: 0 0 23px;

        @include media ('>=tablet') {
            font-size: 73px;
            line-height: 1.0571;
        }

        @include media ('>=widescreen') {
            margin: 0 0 61px 5px;
        }
    }

    .btn-holder {
        .btn {
            @include media ('<tablet') {
                min-width: 266px;
            }
        }
    }
}
