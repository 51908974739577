// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------

.header {
	@include transition(background-color box-shadow);
	padding: 23px 0 20px;
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	right: 0;

	@include media ('>=tablet') {
		padding: 27px 0;
	}

	&.headroom--not-top {
		box-shadow: $box-shadow-sm;
		background-color: $white;
	}

	&.headroom--unpinned {
		box-shadow: none;
	}

	.nav-active & {
		@include media ('<desktop') {
			background-color: $primary;
		}
	}

	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;		
	}
}

.headroom {
	will-change: transform;
	transition: transform 200ms linear;
}

.headroom--pinned {
	transform: translateY(0%);
}

.headroom--unpinned {
	transform: translateY(-100%);
}

.nav-holder {
	@include transition(opacity visibility);

	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: $primary;
	height: calc(100vh - 91px);
	padding: 70px 23px 67px;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
	visibility: hidden;

	@include media ('>=tablet') {
		height: calc(100vh - 101px);
		padding: 67px 48px 67px;
	}

	@include media ('>=desktop') {
		background-color: transparent;
		position: static;
		overflow: visible;
		opacity: 1;
		visibility: visible;
		height: auto;
		padding: 0;
	}

	.nav-active & {
		opacity: 1;
		visibility: visible;
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 137px;
	transition-duration: .3s !important;

	img {
		display: block;

		.nav-active & {
			display: none;

			@include media ('>=desktop') {
				display: block;
			}
		}

		&.mob-logo {
			display: none;

			.nav-active & {
				display: block;

				@include media ('>=desktop') {
					display: none;
				}
			}
		}
	}
}

body:not(.nav-active) {
	.tours-btn,
	.menu li {
		@include media ('<desktop') {
			transition: none !important;
		}
	}
}

.nav-active {
	.menu {
		li {
			@include media ('<desktop') {
				opacity: 1 !important;
				transform: translateY(0px) !important;
			}
		}
	}

	.tours-btn {
		opacity: 1 !important;
		transform: translateY(0px) !important;
	}
}

.menu {
	margin-bottom: 40px;
	font-family: $font-family-sans-serif-secondary;
	font-weight: $font-weight-normal;
	font-size: rem-calc(26);
	text-align: right;

	@include media ('>=desktop') {
		padding-right: 41px;
		text-align: left;
		margin: 0;
	}

	li {
		margin-bottom: 18px;
		@include increment-delay(.2s, 7, .3s);

		@include media ('<desktop') {
			opacity: 0 !important;
			transform: translateY(25px) !important;
			transition-duration: .3s !important;
		}

		@include media ('>=desktop') {
			transition-duration: .3s;
			padding: 0 8px;
			margin-bottom: 0;
		}

		&.active {
			a {
				@include media ('>=desktop') {
					background-color: $white;
					box-shadow: $box-shadow-sm;
				}
			}
		}
	}

	a {
		letter-spacing: 0.03em;
		display: block;
		color: $white;
		position: relative;
		padding-right: 41px;

		@include media ('>=desktop') {
			@include transition(background-color box-shadow);
			font-size: 18px;
			color: $primary;
			border-radius: 3em;
			padding: 6px 28px 4px;
		}

		&:hover, &.active-link {
			@include media ('>=desktop') {
				background-color: $gray-100;
				box-shadow: $box-shadow-sm;
			}

			&::before {
				right: -10px;
			}
		}

		&:before {
			@include transition(right);
			@extend %icon;
			content: $icon-arrow-right;
			position: absolute;
			top: 46%;
			transform: translateY(-50%);
			right: -5px;
			font-size: $font-size-lg;

			@include media ('>=desktop') {
				display: none;
			}
		}
	}
}

.tours-btn {
	width: 138px;
	height: 138px;
	margin-left: auto;
	margin-right: -5px;
	transition-delay: 1.6s !important;
	transition-duration: .3s !important;

	@include media ('<desktop') {
		opacity: 0 !important;
		transform: translateY(25px) !important;
		transition-delay: 1.6s;
	}

	@include media ('>=desktop') {
		width: 78px;
		height: 78px;
		margin: 0 16px 0 0;
	}

	&:hover {
		.pointer {
			@include media ('>=desktop') {
				background-color: $white;
				box-shadow: $box-shadow-sm;
			}
		}

		.pointer-description {
			animation-play-state: paused;
		}
	}

	.pointer {
		@include transition(background-color box-shadow);

		width: 40px;

		@include media ('>=desktop') {
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
		}

		svg {
			width: 100%;
			height: 100%;

			@include media ('>=desktop') {
				width: 24px;
				height: 24px;
			}

			path {
				fill: $white;

				@include media ('>=desktop') {
					fill: $primary;
				}
			}
		}
	}

	.pointer-description {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);
		animation: rotation 15s linear infinite;

		svg {
			width: 100%;
			height: 100%;

			path {
				fill: $white;

				@include media ('>=desktop') {
					fill: $primary;
				}
			}
		}
	}
}

.nav-opener {
	position: static;
	width: 32px;
	height: 32px;
	transition-delay: .3s !important;
	transition-duration: .3s !important;

	@include media ('>=tablet') {
		right: 48px;
		top: 38px;
	}

	.nav-active & {
		span {
			opacity: 0;
		}

		&:before,
		&:after {
			transform: rotate(45deg);
			top: 50%;
			left: 0;
			right: 0;
			background: $white;
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	span,
	&:before,
	&:after {
		background: $primary;
		position: absolute;
		left: 8%;
		right: 8%;
		height: 2px;
		border-radius: 2px;
		transition: all .3s linear;
	}

	&:before,
	&:after {
		content: '';
		top: 9px;
	}

	span {
		margin-top: 3px;
		top: calc(50% - 3px);
	}

	&:after {
		top: calc(100% - 9px);
	}
}
