.card {
    &:hover {
        &.card-success {
            .btn {
                background-color: #92E0A3;
                border-color: #92E0A3;
            }
        }

        &.card-primary {
            .btn {
                background-color: $dark-blue;
                border-color: $dark-blue;
            }
        }

        &.card-warning {
            .btn {
                background-color: #DFD053;
                border-color: #DFD053;
            }
        }

        &.card-info {
            .btn {
                background-color: #57CBE3;
                border-color: #57CBE3;
            }
        }

        &.card-orange {
            .btn {
                background-color: #E3831C;
                border-color: #E3831C;
            }
        }

        &.card-pink {
            .btn {
                background-color: #DE85AB;
                border-color: #DE85AB;
            }
        }

        .card-img-top {
            img {
                transform: scale(1.05);
            }
        }
    }

    &.card-success {
        .btn {
            background-color: $success;
            border-color: $success;
        }
    }

    &.card-primary {
        .btn {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }

    &.card-warning {
        .btn {
            background-color: $warning;
            border-color: $warning;
        }
    }

    &.card-info {
        .btn {
            background-color: $info;
            border-color: $info;
        }
    }

    &.card-orange {
        .btn {
            background-color: $orange;
            border-color: $orange;
        }
    }

    &.card-pink {
        .btn {
            background-color: $pink;
            border-color: $pink;
        }
    }

    &.card-blog {
        width: 100%;

        &:hover {
            &.card-success {
                .card-body {
                    background-color: #EEFEF1;
                }
            }

            &.card-purple {
                .card-body {
                    background-color: #EEEAF2;
                }
            }

            &.card-warning {
                .card-body {
                    background-color: #FDFADE;
                }
            }

            &.card-info {
                .card-body {
                    background-color: #E6FAFD;
                }
            }

            &.card-orange {
                .card-body {
                    background-color: #FFE9D2;
                }
            }

            &.card-pink {
                .card-body {
                    background-color: #FFEDF5;
                }
            }
        }

        &.card-success {
            .card-img-top {
                border-bottom-color: $success;
            }
        }

        &.card-purple {
            .card-img-top {
                border-bottom-color: $purple;
            }
        }

        &.card-warning {
            .card-img-top {
                border-bottom-color: $warning;
            }
        }

        &.card-info {
            .card-img-top {
                border-bottom-color: $info;
            }
        }

        &.card-orange {
            .card-img-top {
                border-bottom-color: $orange;
            }
        }

        &.card-pink {
            .card-img-top {
                border-bottom-color: $pink;
            }
        }

        .card-img-top {
            border-width: 0 0 10px;
            border-style: solid;

            @include media ('>=tablet') {
                border-width: 0 0 16px;
            }

            &::before {
                display: none;
            }

            img {
                position: static;
                height: auto;
                max-height: 450px;
            }
        }

        .card-body {
            @include transition(background-color, .3s);
            padding: 16px;

            @include media ('>=tablet') {
                padding: 20px;
            }

            @include media ('>=desktop') {
                padding: 24px 40px 40px;
            }
        }

        .card-title {
            font-size: 16px;
            line-height: 1.25;

            @include media ('>=tablet') {
                font-size: 23.2px;
                line-height: 1.2069;
            }

            @include media ('>=desktop') {
                font-size: 27.2px;
                line-height: 1.1765;
            }
        }
    }

    .card-img-top {
        position: relative;
        overflow: hidden;
        transform: translate3d(0,0,0);

        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;

            @include media ('>=tablet') {
                padding-bottom: 101.1%;
            }
        }

        img {
            @include transition(transform, .5s);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 32px 40px;

        @include media ('>=tablet') {
            padding: 32px 40px 40px;
        }
    }

    .card-category {
        display: block;
        font-size: $font-size-md;
        line-height: 1.4286;
        color: $gray-600;
        margin-bottom: 16px;
    }

    .card-title {
        font-size: 23.3px;
        line-height: 1.25;
        margin: 0 0 auto;

        @include media ('>=tablet') {
            font-size: 27.2px;
            line-height: 1.1852;
        }
    }

    .stretched-link {
        &::after {
            z-index: 10;
        }
    }

    .btn {
        margin-top: 16px;

        @include media ('>=tablet') {
            margin-top: 38px;
        }
    }

    .btn-play {
        width: 82px;
        height: 81px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(242, 244, 247, .8);
        color: $primary;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        line-height: 1;
        border-radius: $rounded-circle;

        &::before {
            @extend %icon;
            content: $icon-play;
            margin-left: 2px;
        }
    }
}

.card-service {
    align-items: center;
    text-align: center;
    background-color: transparent;

    .card-img {
        width: 120px;
        height: 120px;
        margin-bottom: 16px;
    }

    .card-title {
        margin-bottom: 6px;
        font-size: $h3-font-size;
        line-height: 1.1765;
        width: 100%;
    }

    .card-text {
        margin: 0 auto;
        max-width: 282px;
    }
}

.card-links {
    padding-top: 24px;

    @include media ('>=desktop') {
        width: calc(100% - 44px);
    }

    .card-links-title {
        font-size: 19.2px;
        line-height: 1.25;
        margin-bottom: 10px;

        @include media ('>=desktop') {
            font-size: 23.2px;
            line-height: 1.2069;
            margin-bottom: 27px;
        }
    }

    .card-links-list {
        margin: 0 0 38px;
        font-size: 16px;
        line-height: 1.5;

        @include media ('>=desktop') {
            font-size: 18px;
            line-height: 1.4444;
            margin-bottom: 24px;
            border-top: 1px solid $primary;
        }

        &:last-child {
            margin: 0;
        }

        li {
            padding: 12px 0;

            @include media ('>=desktop') {
                padding: 14px 0 17px;
                border-bottom: 1px solid $primary;
            }
        }

        a {
            @include transition(font-weight);
            display: block;
            position: relative;
            padding-right: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                text-decoration: underline;
                font-weight: $font-weight-semibold;
            }

            &::before {
                @extend %icon;
                content: $icon-arrow-right-bold;
                position: absolute;
                top: 57%;
                right: 0;
                transform: translateY(-50%);
                font-size: 12px;
                line-height: 1;

                @include media ('>=desktop') {
                    right: 6px;
                }
            }
        }
    }
}
