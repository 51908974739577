// Image gallery widget
// --------------------------------

.wdm-image-grid {
    overflow: hidden; // clearfix
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.wdm-image-grid--caption {
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    padding: 5px 5px 5px 10px;
}

.wdm-image-grid__item {
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    // we display grid item thumbnail as a background image, so hide by default
    // `img` is used as source for displaying image in fancybox
    img {
        display: none;
    }

    // 3 column grid item
    &--col-3 {
        width: 33.333%;
        padding-top: 33.333%;
        margin-bottom: 34px;
    }

    // 4 column grid item
    &--col-4 {
        width: 25%;
        padding-top: 25%;
        margin-bottom: 34px;
    }

    // 5 column grid item
    &--col-5 {
        width: 20%;
        padding-top: 20%;
        margin-bottom: 34px;
    }

    // 6 column grid item
    &--col-6 {
        width: 16.6%;
        padding-top: 16.6%;
        margin-bottom: 34px;
    }
}

@media(max-width: 1030px) {
    .wdm-image-grid__item {

        &--col-6 {
            width: 33.3%;
            padding-top: 33.3%;
            margin-bottom: 34px;
        }

        &--col-5 {
            width: 33.3%;
            padding-top: 33.3%;
            margin-bottom: 34px;
        }

        &--col-4 {
            width: 33.3%;
            padding-top: 33.3%;
            margin-bottom: 34px;
        }

        &--col-3 {
            width: 33.3%;
            padding-top: 33.3%;
            margin-bottom: 34px;
        }
    }
}

@media(max-width: 700px) {
    .wdm-image-grid__item {

        &--col-6 {
            width: 50%;
            padding-top: 50%;
            margin-bottom: 34px;
        }

        &--col-5 {
            width: 50%;
            padding-top: 50%;
            margin-bottom: 34px;
        }

        &--col-4 {
            width: 50%;
            padding-top: 50%;
            margin-bottom: 34px;
        }

        &--col-3 {
            width: 50%;
            padding-top: 50%;
            margin-bottom: 34px;
        }
    }
}

@media(max-width: 700px) {
    .wdm-image-grid__item {

        &--col-6 {
            width: 100%;
            padding-top: 100%;
            margin-bottom: 34px;
        }

        &--col-5 {
            width: 100%;
            padding-top: 100%;
            margin-bottom: 34px;
        }

        &--col-4 {
            width: 100%;
            padding-top: 100%;
            margin-bottom: 34px;
        }

        &--col-3 {
            width: 100%;
            padding-top: 100%;
            margin-bottom: 34px;
        }
    }
}
