/*# 05. Accordion Additional Styles #*/

.PaneContent {
    padding: 20px;
}

.PaneHeader {
    position: relative;
    display: block;
    margin-bottom: 2px;
    padding: 8px 35px 8px 10px;
    background: #939598;
    color: #fff;
    width: 100%;
    border: 0;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    &__icon {
        margin-right: 12px;
    }
    .SelectedPaneHeader__icon {
        display: none;
    }
}

.PaneHeader:hover {
    cursor: pointer;
}

.SelectedPaneHeader {
    background: #1e6fb6;
    color: #fff;
    display: block;
    position: relative;
    padding: 8px 35px 8px 10px;
    width: 100%;
    border: 0;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    .PaneHeader__icon {
        display: none;
    }
    &__icon {
        margin-right: 12px;
    }
}

.SelectedPaneHeader:hover {
    cursor: pointer;
}

.PaneContent {
    background: #f3f3f4;
    padding: 10px;
}

.SelectedPaneHeader .open {
    display: none;
}

.PaneHeader .close {
    display: none;
}