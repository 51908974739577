/* custom scrollbars styles */

.jcf-scrollable-wrapper {
    box-sizing: content-box;
    position: relative;
}

.jcf-scrollbar-vertical {
    position: absolute;
    cursor: default;
    background: #e3e3e3;
    width: 14px;
    bottom: 0;
    right: 0;
    top: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-dec,
.jcf-scrollbar-vertical .jcf-scrollbar-inc {
    background: #bbb;
    height: 14px;
    width: 14px;
    left: 0;
    top: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-inc {
    top: auto;
    bottom: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-handle {
    background: #888;
    height: 1px;
    width: 14px;
}

.jcf-scrollbar-horizontal {
    position: absolute;
    background: #e3e3e3;
    right: auto;
    top: auto;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 14px;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    background: #bbb;
    height: 14px;
    width: 14px;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-inc {
    left: auto;
    right: 0;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-slider {
    display: inline-block;
    position: relative;
    height: 14px;
}

.jcf-scrollbar-horizontal .jcf-scrollbar-handle {
    position: absolute;
    background: #888;
    height: 14px;
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle {
    visibility: hidden;
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-dec,
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-inc {
    background: #e3e3e3;
}