﻿.image-links-gallery {

    .image-links-gallery-column {
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: start;
        align-items: stretch;

        &:nth-child(2) {
            margin-top: 40px;
        }
    }
}
