// -----------------------------------------------------------------------------
// This file contains all styles related to the common layout of the site
// -----------------------------------------------------------------------------
body {
	transition: background-color 1s ease;

	&.nav-active {
		overflow: hidden;
	}
}

.wrap {
    padding: 0 16px;
	margin-right: auto;
    margin-left: auto;
	width: 100%;
}

@media (min-width: 768px) {
    .wrap {
        padding: 0 48px;
    }
}

@media (min-width: 1400px) {
    .wrap {
        padding: 0 120px;
        max-width: 1440px;
    }
}

.wrapper {
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-top: 97px;

	@include media ('>=tablet') {
		padding-top: 108px;
	}

	@include media ('>=desktop') {
		padding-top: 132px;
	}
}

.resize-active {
	* {
		transition: none !important;
	}
}

.container {
	padding: 0 16px;

	@include media ('>=tablet') {
		padding: 0 48px;
	}

	@include media ('>=xl') {
		padding: 0 120px;
		max-width: 1440px;
	}
}

.fancybox-slide {
	@include media ('>=tablet') {
		padding: 70px;
	}

	@include media ('>=desktop') {
		padding: 50px 100px;
	}

	@include media ('>=widescreen') {
		padding: 100px 198px;
	}
}

.fancybox-bg {
	background-color: rgba($black, .95);
	opacity: 1;

	.fancybox-is-open & {
		opacity: 1;
	}
}

.fancybox-toolbar {
	visibility: visible !important;
	opacity: 1 !important;
}

.fancybox-button {
	&--zoom, &--play, &--thumbs {
		display: none !important;
	}
	&--close {
		@include transition(background-color);
		@include size(30px);

		border-radius: 50%;
		color: $primary;
		background-color: rgba($white, .9);
		top: 10px;
		right: 10px;
		padding: 5px;

		@include media ('>=tablet') {
			@include size(48px);

			padding: 10px;
		}

		@include media ('>=desktop') {
			top: 20px;
			right: 20px;
		}

		@include media ('>=widescreen') {
			top: 48px;
			right: 48px;
		}

		&:hover {
			background-color: $white;
			color: $primary;
		}
	}
}

.stories-section {
	.section-title {
		margin: 0;

		@include media ('>=tablet') {
			font-size: 40px;
			margin: 0 36px 0 0;
		}
	}

	.section-heading {
		padding-bottom: 30px;

		@include media ('>=tablet') {
			padding-bottom: 72px;
		}

		@include media ('>=widescreen') {
			margin-left: -8px;
		}
	}

	.btn-holder {
		.btn {
			min-width: 266px;
		}
	}
}

.stories-slider-wrapp {
	position: relative;

	.controls {
		padding-top: 40px;
		display: flex;
		justify-content: flex-end;
		gap: 10px;

		@include media ('<tablet') {
			display: none;
		}
	}
}

.stories-slider {
	margin: 0 -8px 32px;

	@include media ('>=tablet') {
		margin: 0 -12px;
	}

	@include media ('>=widescreen') {
		margin: 0 -20px;
	}

	.slick-list {
		overflow: visible;
	}

	.slick-track {
		display: flex;
	}

	.slide {
		@include increment-delay(.1s, 10);

		padding: 0 4px;
		height: auto;
		width: calc(336px + 8px);

		@include media ('>=tablet') {
			padding: 0 12px;
			width: calc(336px + 24px);
		}
	}

	.card {
		height: 100%;
	}
}

.services-section {
	.display-1 {
		font-size: 35px;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 32px;
		color: $darken-blue;

		@include media ('>=tablet') {
			margin-bottom: 87px;
			font-size: 40px;
			text-align: left;
		}

		@include media ('>=desktop') {
			font-size: 69px;
		}
	}
}

.services-slider {
	@include media ('>=tablet') {
		margin: 0 -20px;
	}

	@include media ('>=widescreen') {
		margin: 0 -28px;
	}

	.slide {
		padding-bottom: 28px;

		@include media ('>=tablet') {
			@include increment-delay(.1s, 10);
		}

		@include media ('>=tablet') {
			width: 33.33%;
			padding: 0 20px 40px;
		}

		@include media ('>=widescreen') {
			padding: 0 68px 65px;
		}
	}
}

.slick-arrow {
	@include transition(background-color color);
	@include size(32px);
	border-radius: 50%;
	background-color: white;
	border: 1px solid $gray-700;
	color: $gray-600;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	line-height: 1;


	&.slick-prev {
		i {
			margin-right: 2px;
		}
	}

	&.slick-next {
		i {
			margin-left: 2px;
		}
	}

	&.slick-disabled {
		background-color: $gray-200;
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		background-color: $gray-300;
	}

	i {
		color: $gray-700;
	}
}

.slick-dots {
	@extend %listreset;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	li {
		margin: 0 8px;

		&.slick-active {
			button {
				background-color: $primary;
			}
		}
	}

	button {
		@include transition(background-color);
		@include size(12px);

		border: none;
		background-color: $gray-300;
		border-radius: 50%;
		padding: 0;
		font-size: 0;
		line-height: 0;
		display: block;

		&:hover {
			background-color: $primary;
		}
	}
}

.cta-panel {
	text-align: center;
	padding: 60px 0;
	margin: 0 16px;
	position: relative;

	@include media ('>=tablet') {
		margin: 150px 0;
		text-align: left;
		padding: 80px 0;
	}

	&.in-viewport {
		.bg {
			@include media ('<tablet') {
				animation: slide-right .5s ease-out forwards;
			}
		}

		.cta-panel-fade {
			opacity: 0;
		}
	}

	.bg {
		position: absolute;
		background-color: inherit;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		&[class*="bg-"] ~ .container .btn.btn-cta {
			background-color: $white;
			border-color: $white;
			color: $primary;

			&:active,
			&:hover {
				background-color: $gray-200;
				border-color: $gray-200;
			}
		}
	}

	.btn.btn-cta {
		background-color: $darken-green;
		border-color: $darken-green;
		color: $white;

		&:active,
		&:hover {
			background-color: #015013;
			border-color: #015013;
		}
	}

	.btn {
		padding-left: 36px;
		padding-right: 36px;
	}

	h2 {
		color: $darken-green;
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 24px;

		@include media ('>=desktop') {
			font-size: 53.2px;
			line-height: 1.109;
		}
	}

	.text-col {
		@include media ('>=widescreen') {
			padding-left: 11px;
		}
	}

	.cta-panel-fade {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 100;
		background-color: white;
		opacity: 1;
		transition: opacity .7s ease-in;
		pointer-events: none;
	}
}

.cta-img-slider {
	margin: 0 -32px 17px;

	@include media ('>=tablet') {
		position: relative;
		width: 100%;
		max-width: 69%;
		margin: 0 0 0 22%;
	}

	@include media ('>=desktop') {
		margin: 0 0 0 33%;
	}

	@include media ('>=widescreen') {
		margin: 0 0 0 39%;
	}

	&::before {
		
	}

	.in-viewport & {
		@include media ('>=tablet') {
			.slide {
				&.slide-1 {
					left: -58%;
					top: 0;
				}

				&.slide-2 {
					left: 4%;
					top: -56%;
				}

				&.slide-3 {
					left: 13%;
					bottom: -38%;
				}
			}
		}
	}

	&::before {
		@include media ('>=tablet') {
			content: '';
			padding-bottom: 100%;
			display: block;
		}
	}

	.slick-list {
		@include media ('<tablet') {
			padding: 37px 15.3% !important;
		}
	}

	.slide {
		@include media ('<tablet') {
			@include transition(transform margin-top, .7s, ease-in);

			position: relative;
			transform: scale(1);
			margin-top: -10px;
		}

		@include media ('>=tablet') {
			@include transition(top left bottom, .7s, ease-in);
			transition-delay: .2s;
			position: absolute;
		}

		&[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
			@include media ('<tablet') {
				transform: scale(1);
				z-index: 2;
				margin-top: 10px;
			}
		}

		&::before {
			@include media ('<tablet') {
				content: '';
				padding-bottom: 100%;
				display: block;
			}
		}

		&.slick-center,
		&[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
			@include media ('<tablet') {
				transform: scale(1.16);
				z-index: 3;
				margin-top: 0;
			}
		}

		&.slick-center:not(.slick-cloned) + .slick-slide {
			@include media ('<tablet') {
				margin-top: 10px;
			}
		}

		img {
			@include media ('<tablet') {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@include media ('>=tablet') {
				width: 100%;
				height: auto;
			}
		}

		&.slide-1 {
			@include media ('>=tablet') {
				left: -28px;
				top: -3px;
				z-index: 3;
				width: 84.3%;
			}
		}

		&.slide-2 {
			@include media ('>=tablet') {
				left: 0;
				top: -54px;
				z-index: 1;
				width: 99.3%;
			}
		}

		&.slide-3 {
			@include media ('>=tablet') {
				left: 5px;
				bottom: 0;
				z-index: 2;
				width: 101%;
			}
		}
	}
}

.tour-section {
	padding: 10px 0;

	&[data-color] {
		.contact-panel-heading {
			color: #171740;
		}
	}

	.contact-panel-heading {
		font-size: 35px;
		line-height: 1.2;
		margin: 0;

		@include media ('>=tablet') {
			font-size: 70px;
			line-height: 1.0571;
		}

		@include media ('>=desktop') {
			font-size: 69px;
		}
	}

	.btn {
		@include media ('<tablet') {
			min-width: 266px;
		}
	}

	.column {
		.text-holder {
			display: flex;
			flex-direction: column;
			gap: 10px;

			@include media ('>=tablet') {
				font-size: 25px;
				line-height: 1.4;
			}

			@include media ('>=desktop') {
				padding-bottom: 0;
			}

			p {
				margin: 0;
			}
		}

		&:first-child {
			padding-bottom: 30px;

			@include media ('>=desktop') {
				padding-bottom: 0;
			}
		}
	}
}

.form-card {
	box-shadow: $box-shadow-sm;
	padding: 24px 24px 48px;
	background-color: $white;
	border-radius: 16px;

	@include media ('>=tablet') {
		padding: 48px;
		border-radius: 4px;
	}

	@include media ('>=xl') {
		margin-right: -25px;
		margin-left: 28px;
	}

	h3 {
		font-size: 23.2px;
		line-height: 1.2069;
		margin-bottom: 14px;

		@include media ('>=tablet', '<desktop') {
			font-size: 27.2px;
			line-height: 1.1765;
		}

		img {
			margin: -4px 7px 0 0;
			vertical-align: middle;
			width: 23px;
			height: 24px;

			@include media ('>=tablet', '<desktop') {
				width: 27px;
				height: 28px;
				margin-top: -7px;
			}
		}
	}

	.card-heading {
		font-size: 16px;
		line-height: 1.5;
		padding-bottom: 29px;

		@include media ('>=desktop') {
			padding-bottom: 34px;
		}

		p {
			&:last-child {
				margin: 0;
			}
		}
	}
}

.form {
	.form-group {
		margin-bottom: 36px;
		position: relative;
		background-color: $gray-200;
		border-radius: 4px 4px 0 0;
		padding-top: 7px;

		@include media ('>=tablet') {
			margin-bottom: 30px;
			padding-top: 10px;
		}

		@include media ('>=desktop') {
			padding-top: 8px;
		}

		&.message-group {
			padding-top: 11px;
			margin-bottom: 32px;

			@include media ('>=tablet') {
				margin-bottom: 32px;
				padding-top: 14px;
			}

			.form-label {
				margin-bottom: 9px;

				@include media ('>=tablet') {
					margin-bottom: 0;
				}
			}
		}

		&.recaptcha-container {
			background-color: white;
			padding-top: 0;
			display: flex;
			justify-content: center;
		}

		.form-label {
			padding: 0 17px;
			font-size: $font-size-sm;
			line-height: 1.3333;
			font-weight: 600;
			letter-spacing: .4px;
			margin: 0;
			display: block;

			@include media ('>=tablet') {
				padding: 0 17px;
				letter-spacing: 0;
			}
		}		
	}

	.form-control {
		background-color: transparent;
		border-color: $primary;
		border-width: 0 0 1px;
		outline: none;
		font-size: 16px;
		line-height: 1.5;
		padding: 0 17px 8px;
		border-radius: 0;

		@include media ('>=tablet') {
			padding: 3px 17px 10px;
		}

		@include media ('>=desktop') {
			padding-bottom: 12px;
		}

		@include placeholder {
			color: $primary;
		}

		&:focus {
			box-shadow: none;
			border-color: $success;
		}
	}

	textarea.form-control {
		resize: none;
		height: 120px;
		min-height: 120px;

		@include media ('>=tablet') {
			background-color: rgba(25, 73, 145, 0.02);
			height: 142px;
			min-height: 142px;
		}
	}

	.btn-holder {
		margin-top: -4px;
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-size: 16px;
		line-height: 1.25;
		padding: 16px 23px;

		i {
			margin-right: 10px;
			font-size: 20px;
			line-height: 1;
		}
	}
}

.insta-section {
	padding: 100px 0;

	@include media ('>=desktop') {
		padding: 127px 0 240px;
	}

	&[data-color] {
		h2 {
			color: $darken-blue;
		}
	}

	h2 {
		font-size: 35px;
		line-height: 1.2;
		margin-bottom: 32px;

		@include media ('>=tablet') {
			font-size: 40px;
		}

		@include media ('>=desktop') {
			font-size: 58px;
			line-height: 1.087;
			margin-bottom: 29px;
		}

		@include media ('>=widescreen') {
			font-size: 69px;
		}
	}

	.insta-content {
		margin: 0 -16px 32px;
		padding: 0 16px;

		@include media ('>=tablet') {
			margin: 0;
			padding: 0;
		}

		img {
			display: block;
			width: 100%;
		}
	}

	.text-col {
		@include media ('>=tablet') {
			padding-left: 20px;
		}

		@include media ('>=widescreen') {
			padding-left: 25px;
		}

		@include media ('>=xl') {
			padding-left: 52px;
		}
	}

	.btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 266px;
		padding-right: 46px;

		@include media ('>=tablet') {
			min-width: 200px;
			padding-right: 30px;
		}

		@include media ('>=desktop') {
			min-width: 210px;
		}

		span {
			margin-right: 28px;
			margin-top: -2px;
			font-size: 22px;
			line-height: 1;

			@include media ('>=tablet') {
				margin-top: -4px;
				font-size: 20px;
				margin-right: 16px;
			}
		}
	}
}

.section-blog {
	padding: 50px 0 140px;

	@include media ('>=desktop') {
		padding: 96px 0 106px;
	}

	h1 {
		color: $primary;
		margin: 0 auto 50px;
		max-width: 430px;

		@include media ('>=tablet') {
			margin-bottom: 64px;
		}

		@include media ('>=desktop') {
			font-size: 60px;
			max-width: 700px;
			margin: 0 auto 96px;
			color: #171740;
		}
	}

	.grid-masonry {
		[class*="col-"] {
			@include increment-delay(.1s, 50, .1s);
			transition-duration: .3s;
		}
	}
}

.grid-masonry {
	margin: 0 -8px;

	&.item-grid {
		[class*="col-"] {
			@include media ('<tablet') {
				padding-bottom: 24px;
			}
			// &:nth-child(3n) {
			// 	@include media ('<tablet') {
			// 		width: calc(100% - 42px);
			// 	}
			// 	.card.card-blog {
			// 		.card-img-top {
			// 			img {
			// 				@include media ('<tablet') {
			// 					height: 60vw;
			// 					object-fit: cover;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			// &:nth-child(5),
			&:nth-child(1) {
				@include media ('<tablet') {
					margin-top: 19px;
				}
			}

			&:nth-child(2) {
				@include media ('>=desktop') {
					margin-top: 40px;
				}
			}

			&.grid-100 {
				@include media ('<tablet') {
					width: 100%;
				}
			}
		}
	}

	@include media ('>=tablet') {
		margin: 0 -12px;
	}

	[class*="col-"] {
		padding: 0 8px 32px;

		@include media ('>=tablet') {
			padding: 0 12px 48px;
		}
	}
}

.section-startup {
	h2 {
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 32px;

		@include media ('>=desktop') {
			font-size: 69px;
			line-height: 1.087;
			margin-bottom: 90px;
		}

		span {
			display: block;
			font-size: 33px;
			line-height: 1.01;

			@include media ('>=desktop') {
				font-size: 40px;
				line-height: 1.1;
			}
		}
	}
}

.landing-page {
	display: flex;
	flex-direction: column;
	gap: 120px;

	.article-rich-text {
		padding-left: 16px;
		padding-right: 16px;

		@include media ('>=tablet') {
			max-width: 600px;
			margin: 0 auto;
		}

		@include media ('>=desktop') {
			max-width: 700px;
		}

		@include media ('>=widescreen') {
			max-width: 900px;
		}
	}

	.article-video {
		padding-bottom: 66px;
		margin: 0 auto;
		max-width: 343px;
		padding-left: 16px;
		padding-right: 16px;

		img {
			display: block;
			width: 100%;
			height: auto;
		}

		@include media ('>=tablet') {
			max-width: 600px;
			margin: 0 auto;
		}

		@include media ('>=desktop') {
			max-width: 700px;
			padding-bottom: 88px;
		}

		@include media ('>=widescreen') {
			max-width: 900px;
		}
	}
}

.article {
	padding: 16px 0 64px;
	background-color: #FAFAFF;

	@include media ('>=tablet') {
		background-color: transparent;
	}

	@include media ('>=desktop') {
		padding: 56px 0 120px;
	}

	.filter-wrapp {
		padding: 0;
		margin-bottom: 36px;
		position: relative;
		z-index: 2;

		@include media ('>=desktop') {
			margin-bottom: 28px;
		}

		&::after {
			display: none;
		}
	}

	&.theme-color-success {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $success 48.96%, rgba($success, 0) 100%);
		}

		.category-bg {
			background-color: $success;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $success;
		}
	}

	&.theme-color-info {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $info 48.96%, rgba($info, 0) 100%);
		}

		.category-bg {
			background-color: $info;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $info;
		}
	}

	&.theme-color-warning {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $warning 48.96%, rgba($warning, 0) 100%);
		}

		.category-bg {
			background-color: $warning;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $warning;
		}
	}

	&.theme-color-orange {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $orange 48.96%, rgba($orange, 0) 100%);
		}

		.category-bg {
			background-color: $orange;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $orange;
		}
	}

	&.theme-color-pink {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $pink 48.96%, rgba($pink, 0) 100%);
		}

		.category-bg {
			background-color: $pink;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $pink;
		}
	}

	&.theme-color-purple {
		.filter-wrapp::before {
			background-image: linear-gradient(to left, $purple 48.96%, rgba($purple, 0) 100%);
		}

		.category-bg {
			background-color: $purple;
		}

		.cta-btn-bg:not(.btn-colored) {
			background-color: $purple;
		}
	}

	.container {
		position: relative;
		z-index: 3;
	}

	h1 {
		margin-bottom: 14px;
		position: relative;
		z-index: 2;

		@include media ('<tablet') {
			color: #171740;
		}

		@include media ('>=desktop') {
			margin-bottom: 40px;
		}
	}

	h2 {
		font-size: 27.2px;
		line-height: 1.1765;
		margin-bottom: 16px;

		@include media ('>=desktop') {
			font-size: 40px;
			line-height: 1.1;
			margin-bottom: 42px;
		}
	}

	h1, h2, h3, h4 {
		color: #22344C;
	}

	h3 {
		@include media ('<desktop') {
			font-size: 23.2px;
		}
	}

	h4 {
		@include media ('<desktop') {
			font-size: 18px;
		}
	}

	.article-content {
		p {
			&:last-child {
				margin: 0;
			}
		}
	}

	.content-holder {
		padding-bottom: 66px;

		@include media ('>=tablet') {
			max-width: 70.4%;
			margin: 0 auto;
		}

		@include media ('>=desktop') {
			padding-bottom: 88px;
		}
	}

	.article-main-image {
		position: relative;

		img {
			position: relative;
		}
	}

	.category-bg {
		position: absolute;
		top: -9999px;
		left: -9999px;
		right: -9999px;
		bottom: 71px;
		z-index: -1;

		@include media ('>=desktop') {
			bottom: 171px;
		}
	}

	.article-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 1.1429;
		padding-bottom: 66px;
		color: #242464;
		position: relative;
		z-index: 2;

		@include media ('>=tablet') {
			color: #22344C;
			padding-bottom: 96px;
		}
	}

	.article-content {
		img {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 64px;

			@include media ('>=desktop') {
				margin-bottom: 100px;
			}
		}

		.grid-masonry {
			img {
				margin: 0;
			}
		}

		p {
			margin-bottom: 1.6rem;
		}

		ul:not([class]):not([id]) {
			@extend %listreset;
			margin-bottom: 1.6rem;
			padding: 5px 0 6px 10px;

			&:last-child {
				margin: 0;
			}

			li {
				position: relative;
				padding-left: 16px;

				&::before {
					content: '';
					width: 3px;
					height: 3px;
					border-radius: 3px;
					background-color: currentColor;
					position: absolute;
					left: 0;
					top: 0.67em;
				}
			}
		}
	}

	.grid-masonry {
		margin-bottom: 0;

		@include media ('>=tablet') {
			margin: 0 -7px 0;
		}

		[class*="col-"] {
			@include increment-delay(.15s, 20);
			transition-duration: .3s;

			@include media ('>=tablet') {
				padding: 0 7px 16px;
			}

			&:first-child {
				margin-top: 20px;

				@include media ('>=desktop') {
					margin-top: 60px;
				}
			}

			&:nth-child(3) {
				@include media ('>=desktop') {
					margin-top: 60px;
				}
			}
		}
	}

	.filter-list {
		li {
			@include media ('>=tablet', '<desktop') {
				padding: 0 10px;
			}
		}
	}

	.btn-holder {
		@include media ('>=desktop') {
			margin-top: -14px;
		}

		.btn.btn-sm {
			@include media ('>=desktop') {
				padding: 17px 23px 15px;
				font-size: 18px;
				line-height: 1.1111;
			}
		}
	}
}

.article-video {
	margin-bottom: 64px;

	@include media ('>=desktop') {
		margin-top: 33px;
		margin-bottom: 100px;
	}
}

.article-rich-text {
	a {
		cursor: pointer;
		color: #1A73E8;
	}
}

.article-share {
	.dropdown-toggle {
		color: #242464;
		font-size: 14px;
		line-height: 1.1429;
		display: inline-flex;
		align-items: center;

		span {
			color: #171740;
			font-size: 22px;
			line-height: 1;
			margin-left: 19px;
		}

		&::after {
			display: none;
		}
	}

	.dropdown-menu {
		margin-top: 16px !important;
		padding: 10px 0;
		min-width: 140px;
		font-size: 16px;
		line-height: 1.5;
		border: none;
		border-radius: 8px;
		box-shadow: 0 4px 20px rgba(108, 105, 105, 0.15);
	}

	.dropdown-item {
		color: $primary;
		display: flex;
		align-items: center;
		padding: 8px 16px;

		&:active {
			background-color: $gray-300;
		}

		span {
			@include size(24px);
			margin-right: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			line-height: 1;

			&.icon-copy {
				font-size: 21px;
			}

			&.icon-twitter {
				font-size: 20px;
			}

			&.icon-facebook {
				font-size: 20px;
			}
		}
	}
}

.section-related {
	padding: 84px 0 71px;

	@include media ('>=desktop') {
		padding: 163px 0 119px;
	}

	&.bg-warning-light {
		background-color: #FDFADE;
	}

	h2 {
		color: #171740;
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 47px;

		@include media ('>=desktop') {
			font-size: 60px;
			line-height: 1.1;
			margin-bottom: 142px;
		}

		br {
			@include media ('>=tablet') {
				display: none;
			}
		}
	}

	.grid-masonry.item-grid {
		[class*="col-"] {
			&:nth-child(3),
			&:nth-child(5) {
				margin-top: 0;

				@include media ('>=tablet') {
					margin: 0;
				}
			}

			&:nth-child(3n) {
				@include media ('<tablet') {
					width: 50%;
				}
			}

			&:nth-child(1) {
				margin-top: 40px;

				@include media ('>=tablet') {
					margin: 0;
				}
			}
		}
	}
}

.viewport-section {
	&.in-viewport {
		.move-effect {
			opacity: 1;
			transform: translateY(0);
		}

		&.move-effect {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.move-effect {
	@include transition(opacity transform, .7s, linear);
	opacity: 0;
	transform: translateY(25px);
}

.white-bg-content {
	a {
		text-decoration: revert;

		&:hover {
			color: blue;
		}
	}
}

.statement-content {
	@include media ('>=tablet') {
		padding-top: 96px;
		padding-bottom: 240px;
	}

	padding-top: 48px;
	padding-bottom: 120px;
}

.grecaptcha-badge {
	visibility: hidden;
}

@keyframes rotation {
	from {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}

	to {
		transform: translate3d(-50%, -50%, 0) rotate(359deg);
	}
}