﻿$card-image-height: 120px;
$card-image-width: 120px;


.card-service {   

    .card-img-container {
        position: relative;
        width: $card-image-width;
        height: $card-image-height;
    }

    .card-img {
        width: $card-image-width;
        height: $card-image-height;
        position: absolute;
        left: 0;
        right: 0;

        &.hidden-img {
            visibility: collapse;
        }
    }
}
