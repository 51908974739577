.intro {
	position: relative;
	margin: 20px 0 0px;
	padding-top: 16px;

	@include media ('>=tablet') {
		padding-top: 0;
		margin: 23px 0 120px;
	}

	@include media ('>=desktop') {
		max-width: 2000px;
		margin: 48px auto 120px;
	}

	.controls {
		display: flex;
		justify-content: flex-end;
		padding-top: 20px;

		@include media ('<desktop') {
			display: none;
		}

		.slick-arrow {
			margin-left: 17px;
		}

		.slick-next {
			margin-right: 16px;
		}
	}

	.rectangle-scale {
		height: 200px;
		width: 200px;
		animation: shrink-box 1s ease-in forwards;
		margin-left: 400px;
	}

	.moving-cover-box {
		opacity: 0;
		z-index: 9;
		position: absolute;
		width: 24px;
		height: calc(100% - 11px);
		transform: translate(-48px, 11px);
		animation: fade .3s ease-in .4s forwards;

		@include media ('<tablet') {
			display: none;
		}

		@include media ('>=desktop') {
			height: calc(100% - 63px);
		}
	}
}

.intro-slider {
	&:not(.slick-initialized) .decor {
		opacity: 0;
	}

	&:not(.slick-initialized) {
		.slide {
			&:not(:first-of-type) {
				display: none;
			}
			.hero-image {
				opacity: 1;
			}
			h1, h2 {
				opacity: 1;
			}
		}
	}

	.anime & {
		.slick-slide {
			&:first-child {
				.decor {
					&.decor-left {
						animation: fade .3s ease-in .4s forwards;
					}

					&.decor-right {
						animation: shrink-box-down .5s ease-out forwards;

						@include media ('>=tablet') {
							animation: slide-right .5s ease-out forwards;
						}

						@include media ('>=desktop') {
							animation: shrink-box-right 1s ease-out forwards;
						}
					}
				}

				h1, h2 {
					animation: fade .3s ease-in .6s forwards;
				}

				.btn-holder {
					animation: fade .3s ease-in .8s forwards;
				}

				.hero-image {
					animation: fade .3s ease-in .4s forwards;
				}
			}
		}
	}

	.container {

		position: relative;
		text-align: center;

		@include media ('>=desktop') {
			text-align: left;
		}
	}

	.hero-content {
		position: relative;
	}

	.image-holder {
		position: relative;

		@include media ('<tablet') {
			display: none;
		}

		@include media ('>=desktop') {
			flex-grow: 1;
		}
	}

	.heading-holder {
		@include media ('>=desktop') {
			flex: 0 0 auto;
			width: 42%;
		}
	}

	h1, h2 {
		opacity: 0;
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 24px;

		@include media ('>=phone') {
			font-size: 56px;
		}

		@include media ('>=tablet') {
			font-size: 63px;
			line-height: 1.0571;
		}

		@include media ('>=1170px') {
			font-size: 73px;
		}

		@include media ('>=widescreen') {
			margin: 0 0 61px 5px;
		}
	}

	.btn-holder {
		opacity: 0;

		.btn {
			@include media ('<tablet') {
				min-width: 266px;
			}
		}
	}

	.hero-image {
		opacity: 0;
		max-width: 343px;

		@include media ('>=tablet') {
			max-width: 482px;
		}

		@include media ('>=desktop') {
			max-width: 620px;
		}
	}

	.slick-list {
		@include media ('<tablet') {
			overflow: visible;
		}
	}

	.slick-slide {
		position: relative;
		padding: 24px 0 0 0;

		@include media ('>=tablet') {
			min-height: 460px;
			padding-top: 16px;
		}

		&.slick-current {
			.decor {
				&.decor-left {
					animation: fade .3s ease-in .6s forwards;
				}

				&.decor-right {
					animation: slide-right 1s ease-out forwards;
				}
			}

			h1, h2 {
				animation: fade .3s ease-in .6s forwards;
			}

			.btn-holder {
				animation: fade .3s ease-in .8s forwards;
			}

			.hero-image {
				animation: fade .3s ease-in 1s forwards;

				@include media ('<tablet') {
					animation-delay: 0.4s;
				}
			}
		}

		.d-flex {
			gap: 48px;

			@include media ('>=desktop') {
				gap: 54px;
			}
		}

		img {
			@include media ('>=desktop') {
				width: 100%;
				height: auto;
			}
		}
	}

	.decor {
		position: absolute;
		top: 16px;
		left: 16px;
		opacity: 1;

		&.decor-right {
			height: calc(100% - 32px);
			width: calc(100% - 32px);
			transform: translateX(100%);

			@include media ('>=desktop') {
				top: -5px;
				left: -16px;
				height: calc(100% + 10px);
				width: calc(100% + 16px);
			}
		}

		&.decor-left {
			opacity: 0;
			top: -5px;
			left: -48px;
			width: 24px;

			@include media ('<tablet') {
				display: none;
			}
		}
	}

	.slick-arrow {
		background-color: transparent;
		border: 1px solid $white;
		color: $gray-700;
		position: absolute;
		z-index: 10;
		bottom: 22px;

		@include media ('<tablet') {
			display: none !important;
		}

		&.slick-prev {
			right: 67px;
		}

		&.slick-next {
			right: 18px;
		}

		&.slick-disabled {
			background-color: $white;
			color: $primary;
			opacity: .5;
		}

		&:hover {
			background-color: $white;
			color: $primary;
		}
	}
}

.intro-img-slider {
	display: none;
	@include media ('<tablet') {
		display: unset;
	}

	position: relative;
	z-index: 3;

	.anime & {
		animation: fade-up .5s ease-out forwards;

		@include media ('>=tablet') {
			animation: slide-right-center .5s ease-out forwards;
		}
	}

	&:not(.slick-initialized) {
		.slide {
			&:not(:first-child) {
				display: none !important;
			}
		}
	}

	.slick-slide {
		position: relative;

		&:not(.slick-current) {
			@include media ('>=tablet') {
				cursor: pointer;
			}
		}

		&::before {
			@include media ('>=tablet') {
				content: '';
				display: block;
				padding-bottom: 107.4%;
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.slick-list {
		@include media ('<tablet') {
			padding: 21px 15.3% !important;
			overflow: visible;
		}

		@include media ('>=tablet') {
			padding-right: 84px;
		}

		@include media ('>=desktop') {
			padding-right: 120px;
		}
	}

	.slide {
		@include media ('<tablet') {
			@include transition(transform margin-top);

			position: relative;
			transform: scale(1);
			margin-top: -10px;
		}

		&[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
			@include media ('<tablet') {
				transform: scale(1);
				z-index: 2;
				margin-top: 10px;
			}
		}

		&::before {
			@include media ('<tablet') {
				content: '';
				padding-bottom: 100%;
				display: block;
			}
		}

		&.slick-center,
		&[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
			@include media ('<tablet') {
				transform: scale(1.16);
				z-index: 3;
				margin-top: 0;
			}
		}

		&.slick-center:not(.slick-cloned) + .slick-slide {
			@include media ('<tablet') {
				margin-top: 10px;
			}
		}
	}
}

@keyframes slide-right {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0%);
	}
}

@keyframes slide-right-vw {
	from {
		transform: translateX(100vw);
	}

	to {
		transform: translateX(0%);
	}
}

@keyframes slide-right-center {
	from {
		transform: translateX(100%) translateY(-50%);
	}

	to {
		transform: translateX(0%) translateY(-50%);
	}
}

@keyframes slide-left {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0%);
	}
}

@keyframes fade-up {
	from {
		transform: translateY(25px);
		opacity: 0;
	}

	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes shrink-box-right {
	from {
		transform: scaleX(1.7);
		transform-origin: 100% 100%;
	}

	to {
		transform: scaleX(1);
		transform-origin: 100% 100%;
	}
}

@keyframes shrink-box-down {
	from {
		transform: scaleY(2);
		transform-origin: 100% 100%;
	}

	to {
		transform: scaleY(1);
		transform-origin: 100% 100%;
	}
}
