﻿.contact-form {
    .form-control {
        position: relative;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: 3px;


        &[value]:not(.form-control[value=""]) {
            background-image: url("../images/tick.png");
        }

        &.input-validation-error,
        &[value].input-validation-error:not(.form-control[value=""]) {
            background-image: url("../images/cross.png");
        }
    }

    .field-validation-error {
        background-color: white;
        color: #E04034;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
    }

    .form-group {

        &.checkbox-container,
        &.radio-container,
        &.multiple-choice {
            border: none;
            border-bottom: 1px solid $primary;

            .form-control {
                border: 1px solid #111A26;
                width: 24px;
                height: 24px;
                display: inline-block;
                padding: 0;
                border-radius: 4px;
                background-image: none;
                cursor: pointer;
                flex-shrink: 0;
            }

            label {
                cursor: pointer;
                font-size: 1rem;
            }
        }

        &.checkbox-container,
        &.multiple-choice {
            .form-control {
                position: relative;
                background-image: none !important;

                &:checked::after {
                    position: absolute;
                    content: ' ';
                    display: block;
                    width: 16px;
                    height: 8px;
                    transform: rotate(-45deg);
                    border: 3px solid $primary;
                    border-top-color: transparent;
                    border-right-color: transparent;
                    top: 5px;
                    left: 3px;
                    border-radius: 3px;
                }
            }
        }

        &.multiple-choice,
        &.checkbox-container {
            .ktc-checkbox-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px 17px;
            }

            .ktc-checkbox {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 10px;
            }
        }

        &.checkbox-container {
            .ktc-checkbox {
                padding: 10px 17px;
            }
        }

        &.radio-container {
            .ktc-radio-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px 17px;

                .ktc-radio {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    gap: 10px;
                }
            }

            .form-control {
                border-radius: 24px;
                background-image: none !important;

                &:checked::after {
                    position: absolute;
                    content: ' ';
                    display: block;
                    width: 14px;
                    height: 14px;
                    background-color: $primary;
                    border-radius: 24px;
                    top: 4px;
                    left: 4px;
                }
            }
        }
    }
}

.tour-section .formwidget-submit-text {
    min-height: 500px;
    width: 100%;
    background-color: white;
    display: flex;
    padding: 230px 20px 20px 20px;
    justify-content: center;
    font-weight: 700;
    font-size: 23.2px;
    line-height: 28px;
    background-image: url("../images/envelope.png");
    background-position: center 102px;
    background-repeat: no-repeat;
    box-shadow: $box-shadow-sm;
    text-align: center;
}

.contact-panel-section {
    .btn {
        min-width: auto;
    }

    .contact-panel-row .column {
        padding-left: calc(var(--bs-gutter-x) * 1);
        padding-right: calc(var(--bs-gutter-x) * 1);
    }

    .article-video {
        padding: 0;

        img {
            max-height: 381px;
            object-fit: cover;
        }
    }
}

.contact-panel-image-holder {
    width: 100%;
    padding: 20px 0;

    img {
        width: 100%;
        max-width: 100%;
    }
}