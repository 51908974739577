$icomoon-font-family: 'icomoon';
$icomoon-font-path: $base-font-path;
$icomoon-hash: '893nom';

$icon-city: "\e91a";
$icon-education: "\e91b";
$icon-map: "\e91c";
$icon-medical: "\e91d";
$icon-plane: "\e91e";
$icon-ship: "\e91f";
$icon-arrow-left-bold: "\e916";
$icon-arrow-right-bold: "\e917";
$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-close: "\e904";
$icon-play: "\e905";
$icon-family: "\e906";
$icon-heart: "\e907";
$icon-home: "\e908";
$icon-layers: "\e909";
$icon-paper-plane: "\e90a";
$icon-paper-plane-1: "\e90b";
$icon-pointer: "\e90c";
$icon-ramen: "\e90d";
$icon-shopping-bag: "\e90e";
$icon-startup: "\e90f";
$icon-trending: "\e910";
$icon-users: "\e911";
$icon-instagram: "\e912";
$icon-twitter: "\e913";
$icon-facebook: "\e914";
$icon-linkedin: "\e918";
$icon-share: "\e915";
$icon-copy: "\e919";
$icon-arts: "\e920";
$icon-jobs: "\e921";
$icon-nature: "\e922";
$icon-wifi: "\e923";
$icon-train: "\e924";
$icon-coffee: "\e925";
$icon-beach: "\e926";
